:root {
  --autocomplete-active-background-color: #F5F5F5;
  --autocomplete-background-color: #FFFFFF;
  --autocomplete-suggestion-height: 32px;
}

.autocomplete {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 0px !important;
  position: absolute !important;
  top: 100%;
  width: 100%;
  z-index: 999;
}

.autocomplete .suggestions {
  max-height: calc(var(--autocomplete-max-suggestions) * var(--autocomplete-suggestion-height));
  overflow-y: auto;
}

.autocomplete .suggestions .suggestion {
  align-items: center;
  background-color: var(--autocomplete-background-color);
  border-left: 3px solid;
  border-left-color: var(--autocomplete-background-color);
  display: flex;
  height: var(--autocomplete-suggestion-height);
  padding-left: 5px;
  white-space: pre-wrap;
}

.autocomplete .suggestions .suggestion:hover, .autocomplete .suggestions .suggestion.active {
  background-color: var(--autocomplete-active-background-color);
  border-left-color: var(--orange-color);
  cursor: pointer;
}

.autocomplete-loading {
  min-height: 64px;
}

.autocomplete-empty {
  text-align: center;
}
