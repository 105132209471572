.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 2rem !important;
}

.justify-content-center {
  justify-content: center !important;
}
