@import url("./styles/colors.css");
@import url("./styles/flex.css");
@import url("./styles/position.css");
@import url("./styles/spacing.css");
@import url("./styles/text.css");

.App {
  /* Adding padding at the bottom to make room for the Medallia feedback button. Otherwise it can cover content on mobile devices */
  padding-bottom: 100px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
