.text-bold {
  font-weight: bold !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-center {
  text-align: center !important;
}
